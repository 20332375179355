@font-face {
    font-family: "Inter";
    src: url("./fonts/inter/Inter-Regular.ttf") format("truetype");
    font-display: swap;
    font-weight: normal;
}

@font-face {
    font-family: "Inter";
    src: url("./fonts/inter/Inter-Medium.ttf") format("truetype");
    font-display: swap;
    font-weight: 300;
}

@font-face {
    font-family: "Inter";
    src: url("./fonts/inter/Inter-Bold.ttf") format("truetype");
    font-display: swap;
    font-weight: bold;
}

* {
    margin: 0;
    font-family: "Inter", Helvetica;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: #f8e1cd;
}

::selection {
    background: #e8be99;
}

::-moz-selection {
    background: #e8be99;
}
